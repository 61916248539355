<template>
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="d-flex align-end mt-4 headText">
          <h2 class="headTextProject" :style="fontSize">Tetsu</h2>
          <v-spacer></v-spacer>
          <v-btn small text @click="$router.go(-1)" class="orange--text" style="border: 2px solid orange">Back</v-btn>
        </div>
      </div>

      <div class="font-weight-light black--text caption mb-2">
        <p class="desc">(responsive design)</p>
      </div>

      <v-row>
        <v-col class="main-text">
          <br />
          <span class="textInsideProject"> Language: </span>
          JavaScript<br />
          <span class="textInsideProject">Style: </span> CSS
          <br />
          <span class="textInsideProject">Short info: </span>For this project, I
          collaborated with a graphic designer to create a visually appealing
          website using HTML, CSS, and JavaScript. The client's vision was to
          keep the design simple, with minimal text and a strong emphasis on
          showcasing their store offerings through images. The client provided a
          collection of pictures that beautifully represent the products
          available in their store. Together with the graphic designer, we
          successfully brought their vision to life, delivering a visually
          engaging website.

          <div class="d-flex justify-center git-gallery-live">
            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange"
              href="https://github.com/kirk7183/Tetsu_tepisi" target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> GitHub</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" @click="openGallery(0)">
              <i class="orange--text fas fa-bullseye pa-0"> Gallery</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" href="http://tetsu.rs"
              target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> Live project </i>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Gallery -->
    <!-- <v-row class="ma-0">
      <v-col class="px-4">
        <h2 class="">Gallery</h2>
        <v-container class="mt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs6
              sm4
              lg2
              v-for="(image, i) in images"
              :key="i"
              class="d-flex justify-center"
            >
              <img
                v-lazy="image.src"
                :style="picSize"
                style="cursor: pointer"
                @click="openGallery(i)"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Import component LightBox za prikaz galerije sa kontrolama levo i desno-->
    <LightBox ref="lightbox" :media="images" :show-caption="true" :show-light-box="false" :closable="true" />
  </v-card>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

  export default {
    components: { LightBox },
    data() {
      return {
        images: [
          {
            thumb: require("@/assets/projects/Tetsu/slika-13.jpg"),
            src: require("@/assets/projects/Tetsu/slika-13.jpg"),
            caption:
              "<h4 class='pt-2'>Responsive look - desktop and mobile look</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-9.jpg"),
            src: require("@/assets/projects/Tetsu/slika-9.jpg"),
            caption: "<h4 class='pt-2'>Responsive look - laptop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-10.jpg"),
            src: require("@/assets/projects/Tetsu/slika-10.jpg"),
            caption: "<h4 class='pt-2'>Responsive look - laptop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-11.jpg"),
            src: require("@/assets/projects/Tetsu/slika-11.jpg"),
            caption: "<h4 class='pt-2'>Responsive look - laptop and tablet</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-12.jpg"),
            src: require("@/assets/projects/Tetsu/slika-12.jpg"),
            caption: "<h4 class='pt-2'>Responsive look - mobile</h4>",
          },

          {
            thumb: require("@/assets/projects/Tetsu/slika-1.jpg"),
            src: require("@/assets/projects/Tetsu/slika-1.jpg"),
            caption: "<h4 class='pt-2'>Desktop - Home page</h4>",
          },
          // {
          //   thumb: require("@/assets/projects/Tetsu/slika-2.jpg"),
          //   src: require("@/assets/projects/Tetsu/slika-2.jpg"),
          //   caption: "<h4 class='pt-2'>Home page</h4>",
          // },
          {
            thumb: require("@/assets/projects/Tetsu/slika-3.jpg"),
            src: require("@/assets/projects/Tetsu/slika-3.jpg"),
            caption: "<h4 class='pt-2'>Desktop - Home page</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-4.jpg"),
            src: require("@/assets/projects/Tetsu/slika-4.jpg"),
            caption: "<h4 class='pt-2'>Desktop - Home page</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-5.jpg"),
            src: require("@/assets/projects/Tetsu/slika-5.jpg"),
            caption: "<h4 class='pt-2'>Desktop - Products page</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-6.jpg"),
            src: require("@/assets/projects/Tetsu/slika-6.jpg"),
            caption: "<h4 class='pt-2'>Desktop - Maintenance of carpet</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-8.jpg"),
            src: require("@/assets/projects/Tetsu/slika-8.jpg"),
            caption:
              "<h4 class='pt-2'>Desktop - Maintenance of carpet and footer</h4>",
          },
          {
            thumb: require("@/assets/projects/Tetsu/slika-7.jpg"),
            src: require("@/assets/projects/Tetsu/slika-7.jpg"),
            caption: "<h4 class='pt-2'>Location - Google maps</h4>",
          },
        ],
      };
    },
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          // case "xs":
          // case "sm":
          //   return { "font-size": "21px !important" };
          default:
            return {
              "font-size": "1.3em",
              "letter-spacing": "0.05em",
              "text-transform": "uppercase",
            };
        }
      },
      picSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "lg":
            return { width: "95%", height: "80px" };
          default:
            //tj. xs, sm, md
            return { width: "auto", height: "96px", overflow: "hidden" };
        }
      },
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
      },
    },
  };
</script>

<style></style>
